.navigation {
    position: fixed;
    top: 0;
    z-index: 2;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
  }

  .default {
    padding: 20px 0;
    background-color: cornflowerblue;
  }

  .navContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: min(70%, 1400px);
    margin: 0 auto;
    align-items: center;
  }
  
  .navigationLink {
    text-decoration: none;
    font-size: 20px;
    color: white;
  }
  
  .navigationLink:hover {
    color: rgb(203, 225, 255);
    cursor: pointer;
  }

img {
  position: relative;  
  width: 100px;
  height: auto;
}

.active {
  border-bottom: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 3px 6px 0 rgba(57, 63, 68, 0.3);
  background-color: white;
  padding: 20px 0;
}

.active .logo {
  display: none;
}

.active a {
  color: black;
}

.active a:hover {
  color: cornflowerblue;
}

.active img {
  display: none;
}