.aboutContainer {
    position: relative;
    padding-top: 60px;
    padding-bottom: 150px;
    background-color: cornflowerblue;
    color: white;
}

.aboutContent {
    width: min(70%, 1400px);
    text-align: center;
    margin: 0 auto;
}

.aboutContent h2 {
    font-size: 40px;
}

.aboutContent p {
    margin-top: 40px;
    font-size: 28px;
    text-align: justify;
}

.aboutWave {
    position: absolute;
    margin-bottom: -5px;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.aboutWave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 68px;
    transform: rotateY(180deg);
}

.aboutWave .aboutWaveShapeFill {
    fill: #FFFFFF;
}