.contractorsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 40px;
    row-gap: 40px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 40px;
    width: min(70%, 1400px);
  }
  
  
  .contractor {
    width: 47%;
    padding: 20px 20px;
    border-radius: 15px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.25);
    text-decoration: none;
    color: black;
    transition: transform 0.3s;
  }
  
  .contractor:hover {
    transform: scale(1.02);
  }
  
  .contractorTitle {
    font-weight: 600;
    font-size: 22px;
  }
  
  .contractorText {
    margin-top: 18px;
    font-size: 18px;
  }
  
  .contractor:visited {
    color: black;
  }