h2 {
    text-align: center;
    font-size: 40px;
}

.analyticsTitle {
    text-align: center;
    font-weight: 700;
    margin-top: 60px;
    font-size: 40px;
}

.productTitleContainer h2 {
    font-weight: 600;
    font-size: 24px;
    width: min(70%, 1400px);
}

.productTitleContainer {
    display: flex;
    row-gap: 110px;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    text-align: center;
    align-items: center;
    background-color: cornflowerblue;
    color: white;
}

.titleWave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    margin-bottom: -2px;
}

.titleWave svg {
    position: relative;
    display: block;
    width: calc(300% + 1.3px);
    height: 103px;
}

.titleWave .titleWaveShapeFill {
    fill: #FFFFFF;
}

.downButton {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid white;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s;
}

.downButton:hover {
    cursor: pointer;
    transform: scale(1.08);
}

.downButton svg {
    width: 30px;
    height: auto;
}

.selectedProductContainer {
    margin: 0 auto;
}

.descriptionSection {
    height: 90vh;
    width: 80%;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

.descriptionImg {
    width: 45%;
}

.descriptionImg img {
    width: 100%;
}

.swiperPaginationBullet {
    background-color: rgb(150, 163, 174) !important;
    opacity: 0.2 !important;
}

.swiperPaginationBulletActive {
    background-color: rgb(101, 184, 234) !important;
    opacity: 0.9 !important;
    scale: 1.3 !important;
} 

.descriptionSwiper {
    width: 45%;
    height: 55vh;
    display: flex;
    align-items: center;
}

.swiperSlide {
    text-align: justify;
    color: black;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mySwiper {
    width: 100%;
    height: 100%;
}

.structure {
    margin-top: 40px;
    padding-top: 80px;
    padding-bottom: 120px;
    color: white;
    position: relative;
    background-color: cornflowerblue;
}

.structureWave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    margin-bottom: 2px;
    line-height: 0;
}

.structureWave svg {
    position: relative;
    display: block;
    width: calc(120% + 1.3px);
    height: 96px;
}

.structureWave .structureWaveShapeFill {
    fill: #FFFFFF;
}

.structureBottomWave {
    position: absolute;
    margin-bottom: -2px;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.structureBottomWave svg {
    position: relative;
    display: block;
    width: calc(133% + 1.3px);
    height: 39px;
}

.structureBottomWave .structureBottomWaveShapeFill {
    fill: #FFFFFF;
}

.structure p {
    font-weight: 400;
    font-size: 24px;
    width: min(70%, 1400px);
    margin: 0 auto;
    margin-top: 40px;
    text-align: justify;
    line-height: 1.8;
}

.imageWrapper {
    margin: 0 auto;
    margin-top: 40px;
    width: min(70%, 1400px);
    height: auto;
    border-radius: 10px;
    overflow-x: hidden;
    background-color: #FFFFFF;
    padding: 5px 10px;
    box-shadow: 0px 0px 50px 4px rgba(255,255,255,0.3);
    -webkit-box-shadow: 0px 0px 50px 4px rgba(255,255,255,0.3);
    -moz-box-shadow: 0px 0px 50px 4px rgba(255,255,255,0.3);
}

.imageWrapper img {
    width: 100%;
    display: block;
}

.systemParts {
    margin-top: 80px;
}

.parts {
    width: min(70%, 1400px);
    margin: 0 auto;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    row-gap: 15vh;
}

.part {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.part p {
    font-size: 24px;
    font-weight: 400;
    width: 50%;
    text-align: justify;
}

.partImgWrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.part img {
    width: 25%;
    height: auto;
}

.features {
    position: relative;
    margin: 0 auto;
    margin-top: 80px;
    padding-top: 120px;
    padding-bottom: 60px;
    background-color: cornflowerblue;
    color: white;
}

.features h2 {
    font-size: 40px;
}

.featuresWave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.featuresWave svg {
    position: relative;
    display: block;
    width: calc(106% + 1.3px);
    height: 90px;
    transform: rotateY(180deg);
}

.featuresWave .featuresWaveShapeFill {
    fill: #FFFFFF;
}

.features p {
    width: min(70%, 1400px);
    margin: 0 auto;
    margin-top: 80px;
    font-weight: 400;
    font-size: 24px;
    text-align: justify;
}

.videoWrapper {
    width: min(70%, 1400px);
    display: block;
    margin: 0 auto;
    margin-top: 40px;
    box-shadow: 0px 0px 50px 8px rgba(255,255,255,0.3);
    -webkit-box-shadow: 0px 0px 50px 8px rgba(255,255,255,0.3);
    -moz-box-shadow: 0px 0px 50px 8px rgba(255,255,255,0.3);
}

.videoWrapper video {
    display: block;
}