@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  line-height: 1.5;
  font-family: 'Exo 2', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}