.product {
  width: min(70%, 1400px);
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

h1 {
  font-size: 40px;
}

.product h2 {
  text-align: left;
  font-size: 36px;
}

.productItem {
  border-bottom: 1px solid black;
  padding-bottom: 20px;
  padding-top: 18px;
  font-size: 24px;
}

.productItemClickable a{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.productItemMainImg {
  display: block;
  flex-shrink: 0;
  width: 48px;
  margin-left: 20px;
}

.productItemMainImg img {
  width: 100%;
  height: auto;
}

.productItemHoverImg {
  display: none;
  flex-shrink: 0;
  width: 48px;
  margin-left: 20px;
}

.productItemHoverImg img {
  width: 100%;
  height: auto;
}

.productItemClickable:hover {
  background-color: cornflowerblue;
  -webkit-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear
}

.productItemClickable:hover a{
  cursor: pointer;
  color: white;
}

.productItemClickable:hover .productItemMainImg {
  display: none;
}

.productItemClickable:hover .productItemHoverImg {
  display: block;
}