.scrollButton {
    position: fixed;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 3px solid white;
    background-color: cornflowerblue;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scrollButton img{
    height: 25px;
    width: 15px;
    transform: rotate(-90deg)
}

.scrollButton:hover {
    transform: scale(1.05);
    cursor: pointer
}