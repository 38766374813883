.errorPage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.errorPageTitle {
    font-size: 48px;
}

.errorPageLink {
    text-decoration: none;
    font-size: 30px;
}

.errorPageLink:visited {
    color: cornflowerblue;
}