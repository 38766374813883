.contactsContainer {
  position: relative;
  padding-top: 100px;
  padding-bottom: 180px;
  background-color: cornflowerblue;
  color: white;
}

.contactsWave {
  position: absolute;
  margin-top: -2px;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.contactsWave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 102px;
  transform: rotateY(180deg);
}

.contactsWave .contactsWaveShapeFill {
  fill: #FFFFFF;
}

.contactsBottomWave {
  position: absolute;
  margin-bottom: -2px;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.contactsBottomWave svg {
  position: relative;
  display: block;
  width: calc(143% + 1.3px);
  height: 82px;
  transform: rotateY(180deg);
}

.contactsBottomWave .contactsBottomWaveShapeFill {
  fill: #FFFFFF;
}

.contactsContainerContent {
  width: min(70%, 1400px);
  margin: 0 auto;
}

.contactsContentWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 80px;
}

.infoMap {
  width: 100%;
  height: 550px;
  margin: 0 auto;
  margin-top: 40px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 40px;
}

.contactTitle {
  font-size: 36px;
  font-weight: 600;
}

.contactItem {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
  margin-top: 12px;
}

.contactItem img {
  width: 28px;
  height: 28px;
}

.contactText {
  overflow-wrap: break-word;
  white-space: normal;
  font-size: 22px;
}

.contactsRight {
  margin-top: 12px;
}