.privateAccountTitle {
    position: absolute;
    text-align: center;
    font-weight: 700;
    top: 140px;
    font-size: 40px;
}

.productTitleContainer {
    position: relative;
    margin-top: 60px;
    display: flex;
    row-gap: 0vh;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    text-align: center;
    align-items: center;
    background-color: cornflowerblue;
    color: white;
}

.wave {
    position: absolute;
    margin-bottom: -2px;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(175% + 1.3px);
    height: 190px;
    
}

.wave .shapeFill {
    fill: #ffffff;
}

.descriptionSwiper {
    width: 100%;
    height: 100vh;
    display: flex;
}

.mySwiper {
    width: 100%;
    height: 100%;
}

.swiperSlide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiperSlide p{
    font-size: 24px;
    width: min(70%, 1400px);
    margin: 0 auto;
}

.swiperSlide:last-child p {
    width: 100px;
}

.downButton {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 5px solid white;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s;
}

.downButton:hover {
    cursor: pointer;
    transform: scale(1.08);
}

.downButton svg {
    width: 48px;
    height: 48px;
}

.selectedProductContainer {
    margin: 0 auto;
}

.systemParts {
    padding-top: 80px;
}

.systemPartsContentWrapper {
    margin-top: 60px;
}

.systemPartsSubtitle {
    text-align: center;
    padding-top: 20px;
    font-size: 24px;
}

.systemPartsContent {
    width: min(70%, 1400px);
    margin: 0 auto;
}

.systemPartsInfo {  
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1/2fr;
    row-gap: 40px;
    column-gap: 40px;
  }
  
.systemPartsInfoItem {
    display: flex;
    padding: 18px 30px 40px 20px;
    flex-direction: column;
    column-gap: 20px;
    border-radius: 10px;
    border: 1px solid black;
    transition: transform 0.4s;
}

.systemPartsInfoItem:hover {
    transform: translate(5px, -5px)
}

.systemPartsInfoItem:last-child {
    grid-area: 3 / 1 / 4 / 3;
}

.systemPartsInfoItemTitle {
    font-weight: 700;
    font-size: 24px;
}

.systemPartsInfoItem p {
    text-align: justify;
    font-weight: 400;
    font-size: 20px;
    margin-top: 20px;
}

.systemPartsInfoImg {
    flex: 0 0 auto;
    width: 60px;
    height: auto;
}

.systemPartsInfoImg img {
    width: 100%;
    height: auto;
}

.systemPartsImg {
    width: min(70%, 1400px);
    margin: 0 auto;
    margin-top: 80px;
    display: block;
}

.systemPartsImg img {
    width: 100%;
    height: auto;
    display: block;
}

.systemFeaturesList {
    padding-bottom: 80px;
    position: relative;
    background-color: cornflowerblue;
    color: white;
}

.systemFeatures {
    margin-top: 80px;
    padding-top: 100px;
    position: relative;
    background-color: cornflowerblue;
    color: white;
}

.featuresWave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.featuresWave svg {
    position: relative;
    display: block;
    width: calc(114% + 1.3px);
    height: 99px;
}

.featuresWave .featuresWaveShapeFill {
    fill: #FFFFFF;
}

.systemFeatures p {
    width: min(70%, 1400px);
    margin: 0 auto;
    margin-top: 40px;
    font-size: 24px;
    text-align: justify;
}

.imgWrapper {
    width: min(70%, 1400px);
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 10px;
    overflow-x: hidden;
    box-shadow: 0px 0px 40px -10px rgba(255,255,255,0.75);
    -webkit-box-shadow: 0px 0px 40px -10px rgba(255,255,255,0.75);
    -moz-box-shadow: 0px 0px 40px -10px rgba(255,255,255,0.75);
}

.imgWrapper img {
    width: 100%;
    height: auto;
    display: block;
}

@media screen and (min-height: 1000px) {

    .privateAccountTitle {
        top: 20%;
    }

    .swiperSlide {
        top: 10%;
    }

}

@media screen and (min-height: 1200px) {

    .swiperSlide p {
        font-size: 32px;
    }
    
}