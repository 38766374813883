.content-container {
  margin: 0 auto;
  text-align: center;
}

#about {
  padding-top: 140px;
  background-color: cornflowerblue;
}

#products {
  padding-top: 140px;
}

#contacts {
  padding-top: 140px;
}

#contractors {
  padding-top: 140px;
}